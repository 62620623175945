import React from "react";
import Zoom from 'react-reveal/Zoom';
import { graphql, useStaticQuery } from "gatsby";
import BiographyTemplate from "../../templates/components/biography";
import Layout from "../../components/Layout";


const Burak = () => {
  const data = useStaticQuery(graphql`
    query Burak {
      allMarkdownRemark(filter: {frontmatter: {name: {eq: "Burak Bektaş"}}}) {
        edges {
          node {
            id
            frontmatter {
              datesAndPlaces
              backgroundColor
              name
              nameSound
              objects {
                file
                image
                richText
                textLength
                fontSize
                caption
                width
                sources
                posX
                posY
              }
            }
          }
        }
      }
    }
  `);

  const post = data.allMarkdownRemark.edges[0].node;

  return (
    <Layout>
      <Zoom>
        <BiographyTemplate
          objects={post.frontmatter.objects}
          name={post.frontmatter.name}
          datesAndPlaces={post.frontmatter.datesAndPlaces}
          nameSound={post.frontmatter.nameSound}
          backgroundColor={post.frontmatter.backgroundColor}
          hideBackButton
        />
      </Zoom>
    </Layout>
  );
};

export default Burak;
